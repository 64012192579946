<template>
  <v-form ref="form">
    <v-layout wrap justify-center>
      <ServerError v-if="ServerError" />
      <vue-element-loading
        :active="appLoading"
        :is-full-screen="true"
        background-color="#FFFFFF"
        color="blue"
        spinner="mini-spinner"
      />
      <v-flex xs12 lg11 pa-0 pa-sm-6>
        <v-card
          class="mx-auto elevation-2 pa-6"
          max-width="1088px"
          align="center"
          outlined
        >
          <v-layout>
            <v-flex
              xs10
              class="text-left nsbold"
              style="font-weight: 700; font-size: 20px"
            >
              Add Purchase Details</v-flex
            >
            <v-flex xs2 pb-2>
              <v-btn
                dark
                color="#766BC0"
                class="rounded-lg"
                @click="adddialogue = true"
              >
                <v-icon x-small>mdi-plus</v-icon>
                <span
                  class="text-left nsregular"
                  style="font-weight: 400; font-size: 12px"
                  >Add New Item</span
                ></v-btn
              >
            </v-flex>
          </v-layout>
          <v-layout wrap>
            <v-flex xs12>
              <v-card outlined class="pa-2 px-4">
                <v-layout wrap py-2>
                  <v-flex xs12 lg6 pr-0 pr-lg-1>
                    <v-text-field
                      v-model="billNo"
                      dense
                      label="Bill No"
                      outlined
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 lg6 pl-0 pl-lg-1 pt-2 pt-lg-0>
                    <v-autocomplete
                      :items="shopName"
                      v-model="shopid"
                      label="Select Shop"
                      outlined hide-details
                      item-text="shopName"
                      item-value="_id"
                      dense clearable
                      class="my-0 py-0"
                    ></v-autocomplete>
                  </v-flex>
                </v-layout>
                <v-layout wrap py-2>
                  <v-flex xs12 pr-0 pr-lg-1 pt-3 pt-lg-0 lg6 v-if="check1==true">
                    <v-text-field
                      v-model="totalAmount"
                      dense
                      label="Total Cost"
                      outlined
                      type="number"
                      disabled
                      hide-details
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 pl-0 pl-lg-1 pt-2 pt-lg-0 lg6>
                    <v-text-field
                      v-model="discount"
                      dense
                      label="Discount (Rs.)"
                      outlined
                      type="number"
                      hide-details
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 pr-0 pr-lg-1 pt-2 lg6 v-if="check1==true">
                    <v-text-field
                      v-model="payableAmount"
                      dense
                      label="Total amount paid to retailer"
                      outlined
                      type="number"
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 pl-0 pl-lg-1 lg6 pt-2>
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :return-value.sync="purchaseDate"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="purchaseDate"
                          label="Date"
                          outlined
                          readonly
                          dense hide-details
                          class="rounded-0"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="purchaseDate"
                        no-title
                        scrollable
                        @change="$refs.menu.save(purchaseDate)"
                      >
                        <v-spacer></v-spacer>
                      </v-date-picker>
                    </v-menu>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>

            <v-flex xs12 v-if="shopid">
              <v-layout wrap pt-2 pb-4>
                <v-flex xs12 lg3 pr-lg-1>
                  <v-autocomplete
                    :items="kinfOfFood"
                    v-model="kindofFoodtype"
                    label="Select Item"
                    @input="pickMyUnit2(kindofFoodtype)"
                    item-text="itemName"
                    item-value="_id"
                    dense
                    outlined
                  ></v-autocomplete>
                </v-flex>

                <v-flex xs12 lg3 pt-8 pt-lg-0 pr-lg-1>
                  <v-text-field
                    v-model="purchaseAmount"
                    dense
                    @input="cal2()"
                    label="Price per quantity"
                    outlined
                    hide-details
                    type="number"
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 lg3 pt-4 pt-lg-0 pr-lg-1>
                  <!-- :append-outer-icon="myQty" -->
                  <v-text-field
                    :disabled="shopckeck == true"
                    v-model="quantity"
                    dense
                    :label="'Quantity' + ' ' + ' (' + unitType + ')'"
                    outlined
                    hide-details
                    type="number"
                    @input="cal2()"
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 lg3>
                  <v-select
                    v-model="gsttype"
                    :items="itemArray"
                    item-text="gst"
                    label="GST type"
                    dense hide-details
                    outlined
                  ></v-select>
                </v-flex>

                <v-flex xs12 lg3 pt-8 pt-lg-2 pr-lg-1>
                  <v-text-field
                    v-model="taxpercentage"
                    dense
                    label="Tax %"
                    :disabled="gsttype == 'NON GST' ? true : false"
                    outlined
                    hide-details
                    @input="cal2()"
                    type="number"
                  ></v-text-field>
                </v-flex>
                <!-- <v-flex xs12 lg2 pt-8 pt-lg-0 pr-lg-1>
                 <span v-if="purchaseAmount && quantity">
                  {{purchaseAmount*quantity}}
                 </span>
                </v-flex> -->

                <v-flex xs12 lg3 pt-8 pt-lg-2 pr-lg-1 v-if="gsttype == 'IGST'">
                  <v-text-field
                    v-model="taxamnt"
                    dense
                    label="IGST AMOUNT"
                    outlined
                    disabled
                    hide-details
                    type="number"
                  ></v-text-field>
                </v-flex>
                <v-flex
                  xs12
                  lg3
                  pt-8
                  pt-lg-2
                  pr-lg-1
                  v-if="gsttype == 'SGST/CGST'"
                >
                  <v-text-field
                    v-model="SGST"
                    dense
                    label="SGST AMOUNT"
                    outlined
                    disabled
                    hide-details
                    type="number"
                  ></v-text-field>
                </v-flex>
                <v-flex
                  xs12
                  lg3
                  pt-8
                  pt-lg-2
                  pr-lg-1
                  v-if="gsttype == 'SGST/CGST'"
                >
                  <v-text-field
                    v-model="CGST"
                    dense
                    label="CGST AMOUNT"
                    outlined
                    disabled
                    hide-details
                    type="number"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 lg3 pt-8 pt-lg-2 pr-lg-1>
                  <v-text-field
                    v-model="taxamnt"
                    dense
                    label="TAX AMOUNT"
                    outlined
                    disabled
                    hide-details
                    type="number"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 lg3 pt-8 pt-lg-2 pr-lg-1>
                  <v-text-field
                    v-model="TOTAL"
                    dense
                    label="TOTAL"
                    outlined
                    hide-details
                    type="number"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 lg3 pt-2 pt-lg-2>
                  <center>
                    <v-btn block
                      :disabled="shopckeck == true"
                      dark
                      color="#766BC0"
                      dense
                      @click="addValues()"
                    >
                      Add <v-icon x-small>mdi-plus</v-icon>
                    </v-btn>
                  </center>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12>
              <v-divider></v-divider>
            </v-flex>
            <v-flex xs12 py-2 v-if="shopid">
              <v-card flat>
                <v-layout wrap>
                  <v-flex xs12 pt-1 v-for="(item, i) in itemz" :key="i">
                    <v-layout wrap pt-2>
                      <v-flex xs12 lg3 pr-lg-1>
                        <v-autocomplete
                          :items="kinfOfFood"
                          v-model="item.itemId"
                          label="select Item"
                          solo
                          @input="pickMyUnit(item.itemId, i)"
                          item-text="itemName"
                          item-value="_id"
                          dense
                          class="my-0 py-0"
                        ></v-autocomplete>
                      </v-flex>
                      <v-flex xs12 lg2 pt-8 pt-lg-0 pr-lg-1>
                        <v-text-field
                          v-model="item.purchaseAmount"
                          dense
                          label="Price per quantity"
                          type="number"
                          outlined
                          disabled
                          :rules="[rules.required]"
                          hide-details
                          @input="calcPrice()"
                        ></v-text-field>
                      </v-flex>

                      <v-flex xs12 lg2 pt-4 pt-lg-0 pr-lg-1>
                        <!-- :append-outer-icon="myQty" -->
                        <v-text-field
                          v-model="item.quantity"
                          dense
                          type="number"
                          :label="'Quantity' + ' ' + ' (' + item.unitType + ')'"
                          outlined
                          hide-details
                          disabled
                          :rules="[rules.required]"
                          @input="calcPrice()"
                        ></v-text-field>
                      </v-flex>
                      <!-- <v-flex xs12 lg2 pt-4 pt-lg-0 pr-lg-1> -->
                      <!-- :append-outer-icon="myQty" -->
                      <!-- <v-text-field
                          v-model="item.calcTotal"
                          dense
                          type="number"
                          label="Price per item"
                          solo
                          disabled
                          hide-details
                          :rules="[rules.required]"
                        ></v-text-field> -->
                      <!-- <span v-if="item.calcTotal">
                          
                        {{ item.calcTotal }}
                        </span> -->
                      <!-- </v-flex> -->
                      <!-- <v-flex xs12 lg2 pt-4 pt-lg-0 pr-lg-1>
                        <v-text-field
                          v-model="item.taxpercentage"
                          dense
                          type="number"
                          label="Tax %"
                          outlined
                          disabled
                          hide-details
                          :rules="[rules.required]"
                        ></v-text-field>
                      </v-flex> -->
                      <!-- <v-flex xs12 lg2 pt-4 pt-lg-0 pr-lg-1>
                        <v-text-field
                          v-model="item.SGST"
                          dense
                          type="number"
                          label="SGST AMOUNT"
                          outlined
                          disabled
                          hide-details
                          :rules="[rules.required]"
                        ></v-text-field>
                      </v-flex> -->
                      <!-- <v-flex xs12 lg2 pt-4 pt-lg-2 pr-lg-1>
                        <v-text-field
                          v-model="item.CGST"
                          dense
                          type="number"
                          label="CGST AMOUNT"
                          outlined
                          disabled
                          hide-details
                          :rules="[rules.required]"
                        ></v-text-field>
                      </v-flex> -->
                      <!-- <v-flex xs12 lg2 pt-4 pt-lg-2 pr-lg-1>
                        <v-text-field
                          v-model="item.taxamnt"
                          dense
                          type="number"
                          label="TAXAMOUNT"
                          outlined
                          disabled
                          hide-details
                          :rules="[rules.required]"
                        ></v-text-field>
                      </v-flex> -->
                      <v-flex xs12 lg2 pt-4 pt-lg-0 pr-lg-1>
                        <v-text-field
                          v-model="item.TOTAL"
                          dense
                          type="number"
                          label="TOTAL"
                          outlined
                          disabled
                          hide-details
                          :rules="[rules.required]"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 lg3 pt-2 pt-lg-0>
                        <v-btn
                          dark
                          block
                          style="background-color: #766bc0"
                          dense
                          @click="(dialoge = true), (removeitemIndex = i)"
                        >
                          Remove
                        </v-btn>
                        <!-- <v-btn
                          block
                          dark
                          style="background-color: #766BC0"
                          dense
                          @click="itemz.splice(i, 1) ,calcPrice()"
                        >
                          Remove
                        </v-btn> -->
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-card>
              <!-- <v-layout wrap>
                <v-flex xs12 px-2>
                  <v-btn
                    dark
                    style="background-color: #766BC0"
                    dense
                    block
                    @click="addVariant()"
                  >
                    add item <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout> -->
            </v-flex>
          </v-layout>
          <v-layout wrap v-if="shopid">
            <v-flex pt-1>
              <input
                v-show="false"
                ref="files"
                type="file"
                multiple
                @change="getDocs"
              />
              <v-btn
                dark
                style="background-color: #766bc0"
                block
                @click="$refs.files.click()"
                >Upload Bill</v-btn
              >
            </v-flex>
            <!-- </v-layout>
          <v-layout wrap> -->
            <v-flex xs12>
              <v-chips v-for="(item, i) in bills" :key="i">
                <v-chip
                  class="mt-2"
                  @click:close="bills.splice(i, 1)"
                  close
                  dark
                  color="primary"
                  style="margin-right:6px;"
                >
                  {{ item.name }}
                </v-chip>
              </v-chips>
            </v-flex>
          </v-layout>
          <v-layout wrap v-if="itemz.length > 0">
            <v-flex mt-3>
              <v-btn
                dark
                style="background-color: #766bc0;"
                block
                @click="submit()"
                >Save</v-btn
              >
            </v-flex>
          </v-layout>
          <!------------------------------------------------>
          <!-- <v-layout wrap>
             <v-flex xs12>
              <v-btn > Upload Document</v-btn>
             </v-flex>
             <input 
             v-show = "false"
             ref ="files"
             type = "file"
             multiple
             @change="getDocs"
             />
              
           </v-layout> -->
          <!-- <v-layout wrap>
             <v-flex xs12>
               <v-chips v-for="(item,i) in bills " :key="i">
                 <v-chip
                 
                  @click:close="bills.splice(i,1)"
                 close
                 dark
                 color ="green"
                 style="margin-right:6px;margin-b"
                 >
                  {{item.name}}
                 </v-chip>
               </v-chips>
             </v-flex>
           </v-layout> -->
        </v-card>
        <v-dialog width="400px" v-model="dialoge">
          <v-card width="400px">
            <v-toolbar dark color="grey" dense flat class="body-2">
              <v-toolbar-title> Confirm </v-toolbar-title>
            </v-toolbar>
            <v-card-text class="pa-4 black--text"
              >Are you sure you want to remove this item?
            </v-card-text>
            <v-card-actions class="pt-3">
              <v-spacer></v-spacer>
              <v-btn
                color="grey"
                text
                class="body-2 font-weight-bold"
                @click="dialoge = false"
                >Cancel</v-btn
              >
              <v-btn
                color="primary"
                class="body-2 font-weight-bold"
                outlined
                @click="
                  itemz.splice(removeitemIndex, 1),
                    calcPrice(),
                    (dialoge = false)
                "
                >OK</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-form ref="form">
          <v-dialog width="400px" v-model="adddialogue">
            <v-card width="400px">
              <v-toolbar dark color="#766BC0" dense flat class="body-2">
                <v-toolbar-title> Add Item </v-toolbar-title>
              </v-toolbar>

              <v-layout wrap>
                <v-flex xs12 px-4 pt-4 pb-4>
                  <v-text-field
                    autofocus
                    label="Name"
                    v-model="name"
                    outlined
                    hide-details
                    dense
                    :rules="[(v) => !!v || 'This field is required']"
                  ></v-text-field>
                </v-flex>
                <!-- <v-flex xs12 px-4 pb-2>
             
            <v-select
              :items="typeList"
              v-model="typee"
              clearable
              label="Type"
              item-text="itemCategoryName"
              item-value="_id"
              outlined
              @input="search()"
              dense
            ></v-select>
            </v-flex> -->
                <v-flex xs12 px-4 pb-4>
                  <v-select
                    :items="quantitytypes"
                    v-model="quantityType"
                    clearable
                    hide-details
                    label="Select Type"
                    outlined
                    :rules="[(v) => !!v || 'This field is required']"
                    dense
                  ></v-select>
                </v-flex>
                <v-flex xs12 px-4 pt-2 py-4 v-if="quantityType == 'Weight'">
                  <v-select
                    :items="weightType"
                    v-model="productType"
                    clearable
                    hide-details
                    label="Type"
                    item-text="itemCategoryName"
                    item-id="_id"
                    outlined
                    :rules="[(v) => !!v || 'This field is required']"
                    dense
                  ></v-select>
                </v-flex>
                <v-flex xs12 px-4 pt-2 pb-2 v-if="quantityType == 'Litres'">
                  <v-select
                    :items="litersType"
                    v-model="productType"
                    clearable
                    label="Type"
                    item-text="itemCategoryName"
                    item-id="_id"
                    outlined
                    dense
                  ></v-select>
                </v-flex>
              </v-layout>
              <v-card-actions class="pt-3">
                <v-spacer></v-spacer>
                <v-btn
                  color="grey"
                  text
                  class="body-2 font-weight-bold"
                  @click="adddialogue = false"
                  >Cancel</v-btn
                >
                <v-btn
                  color="#766BC0"
                  class="body-2 font-weight-bold"
                  outlined
                  @click="addCategory()"
                  >OK</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-form>
        <v-snackbar
          :timeout="3000"
          :value="true"
          right
          color="red"
          v-model="snackbar"
        >
          <span style="font-size: 20px"> {{ msg }}</span>
        </v-snackbar>
      </v-flex>
    </v-layout>
  </v-form>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      shopckeck: false,
      adddialogue: false,
      litersType: ["Milli Litre", "Litre"],
      quantitytypes: ["Count", "Weight", "Litres"],
      weightType: ["Gram", "Kilo gram"],
      quantityType: null,
      productType: "",
      name: null,
      // gsttype: "",
      kindofFoodtype: null,
      gsttype: "NON GST",
      itemArray: [
        { gst: "SGST/CGST", abbr: "SY" },
        { gst: "IGST", abbr: "NY" },
        { gst: "NON GST", abbr: "NY" },
      ],
      purchaseAmount: null,
      quantity: null,
      unitType: "",
      rules: {
        required: (value) => !!value || "Required.",
      },
      removeitemIndex: null,
      itemz: [],
      dialoge: false,
      singleitem: {
        itemId: null,
        purchaseAmount: null,
        quantity: null,
        unitType: "",

        taxpercentage: 0,
        SGST: "",
        CGST: "",
        gsttype: "",
        taxamnt: "",
        TOTAL: "",
      },
      purchaseDate: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      shopid: null,
      kinfOfFood: [],
      shopName: [],
      food: "",
      myQty: "",
      // billNo:null,
      snackbar: false,
      msg: null,
      appLoading: false,
      ServerError: false,
      billNo: null,
      totalAmount: null,

      discount: null,
      bills: [],
      taxamnt: 0,
      TOTAL: 0,
      total5: 0,
      taxpercentage: 0,
      SGST: 0,
      CGST: 0,
      tst1: 0,
      tst2: 0,
      tst3: 0,
      tst4: 0,
      allDocuments: new FormData(),
      menu: null,
      uploadId: null,
      cacheAmount: null,
      payableAmount: null,
      check1:false,
savedListCopy:[],
newtotal:0,
    };
  },
  mounted() {
    this.getFoodType();
    this.getShopName();
  },

  watch: {
    billNo() {
      this.checkBill();
    },
    shopid() {
      this.checkBill();
    },
    payableAmount() {
      if (this.totalAmount < this.payableAmount) {
        this.msg = "Total cost and total amount is different!";
        this.snackbar = true;
      }
      //if needed uncomment this
      if (this.payableAmount < this.totalAmount - this.discount) {
        this.msg = "Amount is much lesser than the total!";
        this.snackbar = true;
      }
    },
    totalAmount() {
      this.payableAmount = this.totalAmount - this.discount;
    },
    discount() {
      // if(this.payableAmount)
      this.payableAmount = this.totalAmount - this.discount;
    },
  },
  methods: {
    checkBill() {
      if (this.billNo != null && this.shopid != null) {
        axios({
          method: "post",
          url: "/check/duplicate/bill",
          headers: {
            token: localStorage.getItem("token"),
          },
          data: {
            billNo: this.billNo,
            shopid: this.shopid,
          },
        })
          .then((response) => {
            this.appLoading = false;
            if (response.data.status == true) {
              if (response.data.alreadyExists == true) {
                this.shopckeck = true;
                this.msg = "Bill already exist";
                this.snackbar = true;
              } else {
                this.shopckeck = false;
              }
            } else {
              this.snackbar = true;
              this.msg = response.data.msg;
            }
          })
          .catch((err) => {
            console.log(err);
            this.appLoading = false;
          });
      }
    },
    //add button
    addCategory() {
      if (this.$refs.form.validate()) {
        axios({
          url: "/item/add",
          method: "POST",
          headers: {
            token: localStorage.getItem("token"),
          },
          data: {
            itemName: this.name,
            quantityType: this.productType,
          },
        })
          .then((response) => {
            this.adddialogue = false;
            this.appLoading = false;
            if (response.data.status) {
              this.msg = response.data.msg;
              this.showsnackbar = true;
              this.getFoodType();
              this.name = null;
              this.productType = null;
              this.quantityType = null;
              this.$refs.form.resetValidation();
            } else {
              this.msg = response.data.msg;
              this.showsnackbar = true;
            }

            this.name = null;
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      }
    },
    cal2() {
      this.tst1 =
        this.purchaseAmount * this.quantity * (this.taxpercentage / 100);
      this.TOTAL = this.purchaseAmount * this.quantity + this.tst1;
      this.tst2 = this.purchaseAmount * this.quantity;
      this.tst3 = this.taxpercentage / 2;
      this.SGST = (this.tst3 / 100) * this.tst2;
      this.CGST = this.SGST;
      this.taxamnt = this.tst1;
    },
    addValues() {
      this.check1=true;
      if(this.gsttype!='NON GST' && !this.taxpercentage){
        this.snackbar = true;
        this.msg = "tax percentage cannot be empty";
      }
      else if (
        this.kindofFoodtype != null &&
        this.purchaseAmount != null &&
        this.quantity != null &&
        this.unitType != null 
      ) {
        this.singleitem.itemId = this.kindofFoodtype;
        this.singleitem.purchaseAmount = this.purchaseAmount;
        this.singleitem.quantity = this.quantity;
        this.singleitem.unitType = this.unitType;
        this.singleitem.calcTotal = this.quantity * this.purchaseAmount;
        this.singleitem.taxpercentage = this.taxpercentage;
        this.singleitem.SGST = this.SGST;
        this.singleitem.CGST = this.CGST;
        this.singleitem.taxamnt = this.taxamnt;
        this.singleitem.TOTAL = this.TOTAL;
        this.singleitem.gsttype = this.gsttype;
        this.total5 = this.total5 + this.TOTAL;
        this.calcPrice2();
        // this.calcPrice2(this.purchaseAmount, this.quantity);

        this.addVariant();
      } else {
        this.snackbar = true;
        this.msg = "please fill your form";
      }
    },

    getDocs(event) {
      var myDoc = event.target.files;

      for (let i = 0; i < myDoc.length; i++) {
        this.bills.push(myDoc[i]);
      }
    },

    addVariant() {
      this.itemz.push(JSON.parse(JSON.stringify(this.singleitem)));
      //////
      this.kindofFoodtype = null;
      this.purchaseAmount = null;
      this.quantity = null;
      this.unitType = null;
      this.SGST = null;
      this.CGST = null;

      this.taxamnt = null;

      this.taxpercentage = 0;
      this.TOTAL = null;
      // this.amt = null;
    },
    calcPrice2() {
      var tst1 = 0;
      tst1 =
        this.purchaseAmount * this.quantity * (this.taxpercentage / 100);
      this.TOTAL = this.purchaseAmount * this.quantity + tst1;
      this.tst2 = this.purchaseAmount * this.quantity;
      this.tst3 = this.taxpercentage / 2;
      this.SGST = (this.tst3 / 100) * this.tst2;
      this.CGST = this.SGST;
      this.taxamnt = tst1;
      console.log("singletotal==", this.TOTAL);
      // calcPrice2(val1, val2) {
      // var totalPrice2 = 0;

      // if (val1 && val2) {
      //   totalPrice2 += val1 * val2;
      // }

      // this.totalAmount = this.totalAmount + totalPrice2;
      // this.payableAmount = this.totalAmount - this.discount;
      this.totalAmount = this.totalAmount+this.TOTAL;
      // this.totalAmount = (totalAmount).toFixed(2);
      console.log("totalAmount==", this.totalAmount);

      this.payableAmount = this.payableAmount+this.TOTAL;
      // this.totalAmount = payableAmount.toFixed(2);

      console.log("payableAmount==", this.payableAmount);

    },

    calcPrice() {
      this.check1=false;
      this.newtotal=0;
      // var totalPrice = 0;
      for (let i = 0; i < this.itemz.length; i++){
        this.tst1 =
        this.itemz[i].purchaseAmount * this.itemz[i].quantity * (this.itemz[i].taxpercentage / 100);
      this.itemz[i].TOTAL = this.itemz[i].purchaseAmount * this.itemz[i].quantity + this.tst1;
      this.tst2 = this.itemz[i].purchaseAmount * this.itemz[i].quantity;
      this.tst3 = this.itemz[i].taxpercentage / 2;
      this.itemz[i].SGST = (this.tst3 / 100) * this.tst2;
      this.itemz[i].CGST = this.itemz[i].SGST;
      this.itemz[i].taxamnt = this.tst1;
      console.log("singletotal==", this.itemz[i].TOTAL);

      this.newtotal += parseFloat(this.itemz[i].TOTAL)
      console.log("this.newtotal==", this.newtotal);

      }
      this.totalAmount = this.newtotal;
      this.payableAmount = this.newtotal;
      this.savedListCopy = this.itemz;
      console.log("newarray=", this.savedListCopy);
      console.log("payableAmount=", this.payableAmount);

      this.itemz = this.savedListCopy
      this.check1=true;
      // var calcTotal = 0;
      // for (let i = 0; i < this.itemz.length; i++) {
      //   if (this.itemz[i].quantity && this.itemz[i].purchaseAmount) {
      //     totalPrice += this.itemz[i].quantity * this.itemz[i].purchaseAmount;
      //     this.itemz[i].calcTotal =
      //       this.itemz[i].quantity * this.itemz[i].purchaseAmount;
      //     if (!this.itemz[i].quantity || !this.itemz[i].purchaseAmount) {
      //       this.itemz[i].calcTotal = 0;
      //     }
      //   }
      // }
      // this.totalAmount = totalPrice;
      // this.total5 = totalPrice;

    },

    pickMyUnit(val, id) {
      for (let i = 0; i < this.kinfOfFood.length; i++) {
        if (this.kinfOfFood[i]._id == val) {
          this.itemz[id].unitType = this.kinfOfFood[i].quantityType;
          break;
        }
      }
    },

    pickMyUnit2(val) {
      for (let i = 0; i < this.kinfOfFood.length; i++) {
        if (this.kinfOfFood[i]._id == val) {
          this.unitType = this.kinfOfFood[i].quantityType;
          break;
        }
      }
    },

    getFoodType() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/item/getalllist",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.kinfOfFood = response.data.data;
            this.food = response.data.data.quantityType;

            this.msg = response.data.msg;
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          console.log(err);
          this.appLoading = false;
        });
    },
    getShopName() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/supplier/getalllist",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.shopName = response.data.data;

            this.msg = response.data.msg;
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          console.log(err);
          this.appLoading = false;
        });
    },
    uploadBills(value) {
      for (let i = 0; i < this.bills.length; i++) {
        this.allDocuments.append("photos", this.bills[i]);
      }
      this.allDocuments.append("id", value);
      axios({
        method: "post",
        url: "/upload/bills",
        headers: {
          token: localStorage.getItem("token"),
        },

        data: this.allDocuments,
      })
        .then((response) => {
          if (response.data.status == true) {
            this.snackbar = true;
            this.msg = response.data.msg;
            this.bills = [];
            this.uploadId="";
            this.allDocuments=[];
            location.reload();

            // this.$router.go(-1);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    submit() {
      // allDocuments

      this.sockets.subscribe("New_Purchase", () => {
        this.msg = "Teste";
        this.snackbar = true;
      });

      axios({
        method: "post",
        url: "/purchase/items",
        headers: {
          token: localStorage.getItem("token"),
        },

        // data:this.allDocuments
        data: {
          shopid: this.shopid,
          billNo: this.billNo,
          totalAmount: this.totalAmount,
          discount: this.discount,
          items: this.itemz,
          payableAmount: this.payableAmount,
          purchaseDate: this.purchaseDate,
        },
      })
        .then((response) => {
          if (response.data.status) {
            // this.snackbar = true;
            // this.msg = response.data.msg;
            this.uploadId = response.data.bill._id;
            if (this.bills.length > 0) {
              this.uploadBills(this.uploadId);
              this.shopid = null;
            this.billNo = null;
            this.totalAmount = null;
            this.discount = null;
            this.payableAmount = null;
            this.purchaseDate = null;
            this.itemz = [];
            }
            else{
            this.msg = response.data.msg;
            this.snackbar = true;
            // this.$router.go(-1);
            this.shopid = null;
            this.billNo = null;
            this.totalAmount = null;
            this.discount = null;
            this.payableAmount = null;
            this.purchaseDate = null;
            this.itemz = [];
            location.reload();
            }
          } else {
            this.msg = response.data.msg;
            this.snackbar = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.select {
  max-width: 60px;
  max-height: 60px;
  font-size: 11px;
}

.col {
  max-width: 60px;
  max-height: 60px;
}
.v-select {
  height: 20px !important;
}
.v-text-field .v-input__control .v-input__slot {
  max-height: 10px !important;
}
</style>
